import {
  Card,
  Checkbox,
  Collapse,
  Grid,
  Group,
  Input,
  InputSharedProps,
  InputWrapperProps,
  List,
  MultiSelect,
  NumberInput,
  Radio,
  Space,
  Stack,
  Switch,
  Text,
  createStyles,
  useInputProps,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MultiTextInput } from "../../components/MultiTextInput";
import { useAppSelector } from "../../hooks/reduxHelper";
import {
  useGetApiRoleGetRolesByOrganizationIdQuery,
  useGetApiRoleGetRolesQuery,
} from "../../services/appcenterApi";
import { selectOrganizationId } from "../user/userSlice";
import { useSecurityPolicyFormContext } from "./SecurityPolicyFormContext";
import { CxDataTable, CxDataTableProps } from "../../components";

const useStyles = createStyles((theme) => ({
  card: {
    //backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    //backgroundColor: theme.colors.dark[7],
    backgroundColor: theme.white,
    overflow: 'visible'
  },

  item: {
    "& + &": {
      //paddingTop: theme.spacing.sm,
      //marginTop: theme.spacing.sm,
      borderTop: `1 solid ${theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[2]
        }`,
    },
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  title: {
    lineHeight: 1,
  },
}));

interface SwitchesCardProps {
  title: string;
  description: string;
  error?: any;
  data?: {
    id: string;
    title: string;
    description: string;
  }[];
  setValue: any;
  children?: any;
}

interface SwitchesCardItemProps
  extends Partial<InputSharedProps>,
  Partial<InputWrapperProps> {
  id: string;
  description: string;
  title: string;
  value: boolean;
  //setValue: any,
  children?: any;
}

function SwitchesCardItem(props: SwitchesCardItemProps) {
  const { value, error, title, children, inputProps, wrapperProps, ...others } =
    useInputProps("SwitchesCardItem", {}, props);

  //const [checked, handlers] = useDisclosure(value);

  //console.log('Switches', others)

  const { classes } = useStyles();

  return (
    <>
      <Group position="apart" className={classes.item} noWrap spacing="xl">
        <div>
          <Text>{title}</Text>
          <Text size="xs" color="dimmed">
            {wrapperProps.description}
            {error}
          </Text>
        </div>
        <Switch
          checked={value}
          onChange={others.onChange}
          onLabel="ON"
          offLabel="OFF"
          className={classes.switch}
          size="lg"
        />
      </Group>
      {children}
    </>
  );
}

function SwitchesCard({
  error,
  title,
  description,
  children,
}: SwitchesCardProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Card withBorder radius="md" p="sm" className={classes.card}>
      <Text fz="lg" className={classes.title} fw={500}>
        {title}
      </Text>
      <Text fz="xs" c="dimmed" mt={3} mb="xl">
        {description}
      </Text>
      {error && (
        <Text fz="xs" c="red" mt={3} mb="xl">
          {t(error, { context: "organizationPolicy" })}
        </Text>
      )}
      {children}
    </Card>
  );
}


export function SecurityPolicyInputs() {
  const form = useSecurityPolicyFormContext();
  const { t } = useTranslation();

  const [dictionary, setDictionary] = useState({});

  const parentHandle = ({ id, value }) => {
    console.log("parentHandle");
    console.log(id);
    console.log(value);
    setDictionary((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    //form.setValues
  };

  const organizationId = useAppSelector(selectOrganizationId);

  const selectedOrganizationId = form.values.organizationId;

  //TODO: Application paging
  const { data: rolesData } = useGetApiRoleGetRolesByOrganizationIdQuery(
    organizationId !== ""
      ? {
        //page: 1,
        //pageSize: 20,
        organizationId: selectedOrganizationId,
      }
      : skipToken
  );

  // @ts-ignore
  const rolesSelectData =
    rolesData == null
      ? []
      : rolesData.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

  useEffect(() => {
    let defaultGuestRole = [];
    if (rolesData != null) {
      let guestRole = rolesData.find((item) => item.name == "Guest");
      if (guestRole != undefined || guestRole != null) {
        defaultGuestRole = [guestRole.id];
      }
    }

    if (form.values["loginTypeCorporate"]) {
      //set initial object for IDP if does not exist.
      if (
        form.values["identityProviders"] &&
        form.values["identityProviders"].length < 1
      ) {
        //set identity providers object
        const idp = [
          {
            identityProvider: "",
            defaultNewRoles: defaultGuestRole,
            whiteListEmailDomain: [],
          },
        ];
        form.setFieldValue("identityProviders", idp);
      }
    }
    console.log("form", form.errors);
  }, [form]);

  const recommendationGroupKey = "Recommendations"

  const searchChangeHandler = function (val, list, setList) {
    let regex = new RegExp("^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){0,3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]\.)?(\/)?(0|[1-2][0-9]|3[0-2]|[0-9])?$");    
    let regex2 = new RegExp("^[0-9\.\/]+$");
    const isValid = regex2.test(val);
    if (!regex.test(val) || !regex2.test(val)) {
      setList([...list]);
      return;
    }

    let recommendations = ['0.0.0.0/0', '0.0.0.0/8', '0.0.0.0/16', '0.0.0.0/24', '0.0.0.0/32'];
    let bitmasks = ['/0', '/8', '/16', '/24', '/32'];
    let recommendedIpArray = ['0', '0', '0', '0'];

    let cidrBlockParts = val.split('/');
    let inputBitMask = "";
    let oct = [];
    if (cidrBlockParts == 2) {
      inputBitMask = cidrBlockParts[1];
    }
    if (cidrBlockParts.length > 0) {
      oct = cidrBlockParts[0].split('.');
    }
    //checks range
    let hasOutOfRange = oct.find(x => parseInt(x) > 255);
    if (hasOutOfRange) {
      setList([...list]);
      return;
    }
    //empty input
    if (oct.length == 0) {
      let recommendationsObject = recommendations.map((x) => { return { value: x, label: x, group: recommendationGroupKey } });
      setList([...list, ...recommendationsObject]);
      return;
    }
    //input either empty or containing 0s
    let lastNonZeroIndex = oct.findLastIndex(e => { return (e !== "0" && e !== "") });
    if (lastNonZeroIndex == -1) {
      let recommendationsObject = recommendations.map((x) => { return { value: x, label: x, group: recommendationGroupKey } });
      setList([...list, ...recommendationsObject]);
      return;
    }

    //else    
    for (let i = 0; i < oct.length; i++) {
      if (oct[i] != '') {
        recommendedIpArray[i] = oct[i];
      }
    }
    let recommendedIpStr = recommendedIpArray.join('.');
    bitmasks.splice(0, lastNonZeroIndex + 1);

    let recommendationsObject = bitmasks.map((x) => {
      let address = recommendedIpStr.concat(x);
      return {
        value: address,
        label: address,
        group: 'Recommendations'
      }
    });
    setList([...list, ...recommendationsObject]);
  }  

  return (
    <>
      <Grid>
        <Grid.Col span={4}>
          <SwitchesCard
            error={form.errors[""]}
            setValue={parentHandle}
            title={t("organizationSecurityPolicy.configureLogin")}
            description={t(
              "organizationSecurityPolicy.configureLogin_description"
            )}
          >
            <Stack>
              <SwitchesCardItem
                {...form.getInputProps("loginTypeEmail")}
                id={"Email"}
                description={t("organizationSecurityPolicy.description_email")}
                title={t("organizationSecurityPolicy.type_email")}
              />

              <SwitchesCardItem
                {...form.getInputProps("loginTypeCode")}
                id={"Code"}
                description={t(
                  "organizationSecurityPolicy.description_staffid"
                )}
                title={t("organizationSecurityPolicy.type_staffid")}
              />

              <SwitchesCardItem
                {...form.getInputProps("loginTypeCorporate")}
                id={"corporate"}
                description={t(
                  "organizationSecurityPolicy.description_corporate"
                )}
                title={t("organizationSecurityPolicy.type_corporate")}
              />
            </Stack>
          </SwitchesCard>
          <Space h="md" />
          <SwitchesCard setValue={parentHandle}
            title={t("organizationSecurityPolicy.common_settings")}
            description={t("organizationSecurityPolicy.common_settings_description")}>
            <Stack>
              <MultiTextInput
                label={t("organizationSecurityPolicy.WhitelistIpAddress")}
                description={t("organizationSecurityPolicy.WhitelistIpAddressDescription")}
                onSearchRecommendation={searchChangeHandler}
                recommendationGroupKey={recommendationGroupKey}
                {...form.getInputProps("whiteListIpAddresses")}
              />
            </Stack>
          </SwitchesCard >

        </Grid.Col>
        <Grid.Col span={8}>
          <Collapse
            in={
              form.values["loginTypeEmail"] ||
              form.values["loginTypeCode"] ||
              form.values["loginTypeMobile"]
            }
          >
            <SwitchesCard
              setValue={parentHandle}
              title={t("organizationSecurityPolicy.additionalPasswordMethods")}
              description={t(
                "organizationSecurityPolicy.additionalPasswordMethods_description"
              )}
            >
              <Stack>
                <MultiTextInput
                  label={t("organizationSecurityPolicy.whiteListEmailDomains")}
                  description={t(
                    "organizationSecurityPolicy.whiteListEmailDomains_description"
                  )}
                  {...form.getInputProps("whiteListEmailDomain")}
                />
                <MultiSelect
                  data={rolesSelectData}
                  label={t("organizationSecurityPolicy.defaultRole")}
                  description={t(
                    "organizationSecurityPolicy.defaultRole_description"
                  )}
                  {...form.getInputProps("defaultNewRoles")}
                />

                <Checkbox
                  checked={form.values.enableAdminOnlyUpdateEmail}
                  description={t("organizationSecurityPolicy.allowUpdateUsername_description")}
                  label={t("organizationSecurityPolicy.allowUpdateUsername")}
                  {...form.getInputProps("allowUpdenableAdminOnlyUpdateEmailateUsername")}
                />

                <Input.Wrapper
                  label={t("organizationSecurityPolicy.passwordRequirements")}
                >
                  <List size={"sm"}>
                    <List.Item>
                      {t("organizationSecurityPolicy.passwordRequireLowerCase")}
                    </List.Item>
                    <List.Item>
                      {t("organizationSecurityPolicy.passwordRequireUpperCase")}
                    </List.Item>
                    <List.Item>
                      {t(
                        "organizationSecurityPolicy.passwordRequireNumericCase"
                      )}
                    </List.Item>
                    <List.Item>
                      {t("organizationSecurityPolicy.passwordMatchEmailCase")}
                    </List.Item>
                  </List>
                </Input.Wrapper>

                <Input.Wrapper
                  label={t(
                    "organizationSecurityPolicy.optionalPasswordRequirements"
                  )}
                >
                  <Checkbox
                    checked={form.values.passwordRequireSpecialCase}
                    label={t(
                      "organizationSecurityPolicy.passwordRequireSpecialCase"
                    )}
                    {...form.getInputProps("passwordRequireSpecialCase")}
                  />
                </Input.Wrapper>

                <Input.Wrapper
                  label={t("organizationSecurityPolicy.passwordLength")}
                >
                  <Group>
                    <NumberInput
                      defaultValue={12}
                      min={12}
                      max={80}
                      {...form.getInputProps("passwordMinLength")}
                    />
                    <Text>between</Text>
                    <NumberInput
                      defaultValue={80}
                      max={80}
                      min={12}
                      {...form.getInputProps("passwordMaxLength")}
                    />
                  </Group>
                </Input.Wrapper>

                <NumberInput
                  min={180}
                  max={365}
                  defaultValue={180}
                  label={t("organizationSecurityPolicy.passwordExpiry")}
                  {...form.getInputProps("passwordExpiry")}
                />
                <NumberInput
                  min={0}
                  defaultValue={0}
                  label={t("organizationSecurityPolicy.lastUsedPasswords")}
                  {...form.getInputProps("passwordHistory")}
                />

                <NumberInput
                  min={0}
                  defaultValue={30}
                  label={t("organizationSecurityPolicy.userInactivityDuration")}
                  description={t("organizationSecurityPolicy.userInactivityDuration_description")}
                  {...form.getInputProps("userInactivityDuration")}
                />

                <SwitchesCardItem
                  {...form.getInputProps("enableTwoFactorAuthentication")}
                  id={"add_otp"}
                  description={t("organizationSecurityPolicy.description_otp")}
                  title={t("organizationSecurityPolicy.add_otp")}
                >
                  <Collapse
                    //in={(dictionary.mobile || dictionary.email || dictionary.staffid) && dictionary.add_otp}
                    //in={form.values["loginTypeEmail"] || form.values["loginTypeMobile"] || form.values["loginTypeCode"]}
                    in={form.values["enableTwoFactorAuthentication"]}
                  >
                    <Stack>
                      <Radio.Group
                        name="otpMethods"
                        label={t("organizationSecurityPolicy.otpMethod")}
                        description={t(
                          "organizationSecurityPolicy.otpMethod_description"
                        )}
                        withAsterisk
                        {...form.getInputProps("twoFactorAuthenticationType")}
                      >
                        <Radio
                          value="TOTP"
                          label={t("organizationSecurityPolicy.otpMethod_time")}
                        />
                        <Radio
                          value="Email"
                          label={t(
                            "organizationSecurityPolicy.otpMethod_email"
                          )}
                        />
                      </Radio.Group>

                      <NumberInput
                        min={0}
                        label={t("organizationSecurityPolicy.invalidAttempt")}
                        {...form.getInputProps("noOfInvalidOtpAttempts")}
                      />

                      <NumberInput
                        min={0}
                        label={t("organizationSecurityPolicy.lockoutDuration")}
                        {...form.getInputProps("accountLockDuration")}
                      />
                    </Stack>
                  </Collapse>
                </SwitchesCardItem>

                <MultiTextInput
                  label={t("organizationSecurityPolicy.TwoFAWhiteListedIpAddress")}
                  description={t(
                    "organizationSecurityPolicy.TwoFAWhiteListedIpAddressDescription"
                  )}
                  onSearchRecommendation={searchChangeHandler}
                  recommendationGroupKey={recommendationGroupKey}
                  {...form.getInputProps("twoFAWhiteListedIpAddress")}
                />
              </Stack>
            </SwitchesCard>
          </Collapse>

          <Space h="md" />

          <Collapse in={form.values["loginTypeCorporate"]}>
            <SwitchesCard
              setValue={parentHandle}
              title={t("organizationSecurityPolicy.corporatePolicy")}
              description={t(
                "organizationSecurityPolicy.corporatePolicy_description"
              )}
            >
              <Stack>

                <Checkbox.Group
                  //name="idp"
                  label={t("organizationSecurityPolicy.idp")}
                  description={t("organizationSecurityPolicy.idp_description")}
                  withAsterisk
                  {...form.getInputProps(
                    "identityProvider.identityProvider"
                  )}
                >
                  <Checkbox
                    value="Google"
                    label={t("organizationSecurityPolicy.idp_google")}
                  />
                  <Checkbox
                    value="Microsoft"
                    label={t("organizationSecurityPolicy.idp_microsoft")}
                  />
                </Checkbox.Group>

                <MultiTextInput
                  //data={rolesSelectData}
                  withAsterisk={true}
                  label={t(
                    "organizationSecurityPolicy.whiteListCorporateEmailDomains"
                  )}
                  description={t(
                    "organizationSecurityPolicy.whiteListCorporateEmailDomains_description"
                  )}
                  //placeholder={t("scope.restrictOauthFlow_placeholder")}
                  {...form.getInputProps(
                    "identityProvider.whiteListEmailDomain"
                  )}
                />

                <MultiSelect
                  data={rolesSelectData}
                  label={t("organizationSecurityPolicy.defaultCorporateRole")}
                  description={t(
                    "organizationSecurityPolicy.defaultCorporateRole_description"
                  )}
                  //placeholder={t("scope.restrictOauthFlow_placeholder")}
                  {...form.getInputProps("identityProvider.defaultNewRoles")}
                />

                <Checkbox
                  checked={form.values.enableLocalAccount}
                  description={t("organizationSecurityPolicy.allowLocalAccount_description")}
                  label={t("organizationSecurityPolicy.allowLocalAccount")}
                  {...form.getInputProps("enableLocalAccount")}
                />


              </Stack>
            </SwitchesCard>
          </Collapse>
        </Grid.Col>
      </Grid>
    </>
  );
}
