import * as React from "react";
import { useAppSelector } from "../../hooks/reduxHelper";
import {
  useGetApiAuditEventByOrganizationIdAndEntityIdPageQuery, 
  useGetApiAuditHeadByOrganizationIdAndEntityIdQuery,
  useGetApiOrganizationGetOrganizationQuery,
  useGetApiRoleGetAllRolesInOrgQuery,
} from "../../services/appcenterApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { Group, List, Stack, Text, ThemeIcon, Timeline, Skeleton, Flex } from "@mantine/core";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { selectOrganizationId } from "../user/userSlice";
import { faCheck, faCross, faEllipsisVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useScrollHook } from "../../hooks/useScrollHook";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function DisplayLineItem({ EventName, EntityName, Timestamp, EventRecord, Application, UserName }, idx, orgName, rolesMapping, showUserLink) {
  const record = JSON.parse(EventRecord);
  //let description = "App: " + Application + " | " + "IP: " + record.UserSourceIp + " | " + "Method: " + record.LoginMethod + " | " + "User Agent: " + record.UserAgent;
  let title = EventName + " " + EntityName;

  if (EventName == "UPDATE") {
    title = EntityName + " updated by " + UserName;
  } else {
    title = EntityName + " created by " + UserName; 
  }  

  if(record != null && record.hasOwnProperty("RoleName") && record["RoleName"] != null && record.hasOwnProperty("EntityEventName")) {
    let roleName = record["RoleName"];
    if(rolesMapping != null && rolesMapping[record["RoleName"]] != null && rolesMapping[record["RoleName"]] != undefined) {
      roleName = rolesMapping[record["RoleName"]];
    }
    if (EntityName == "Organization" && record.hasOwnProperty("UserName") && record["UserName"] != null) {
      let user = record["UserName"];
      let userEditPage = "/users/edit/" + user;
      if(record["EntityEventName"] == "INSERT") {
        if(showUserLink) {
          title = (<span>Added <a href={userEditPage} style={{ textDecoration: 'none', color: 'inherit' }}>{user}</a> user as {roleName} role by {UserName}</span>);
        } else {
          title = "Added " + user + " user as " + roleName + " role by " + UserName;
        }
      } else if(record["EntityEventName"] == "DELETE"){
        if(showUserLink) {
          title = (<span>Removed <a href={userEditPage} style={{ textDecoration: 'none', color: 'inherit' }}>{user}</a> user as {roleName} role by {UserName}</span>);
        } else {
          title = "Removed " + user + " user as " + roleName + " role by " + UserName;
        }
      }
    } else if (EntityName == "User" && record.hasOwnProperty("OrganizationName") && record["OrganizationName"] != null) {
      let organizationName = orgName == "" ? record["OrganizationName"].toString() : orgName;
      if(record["EntityEventName"] == "INSERT") {
        title = "Added into " + organizationName + " organization as " + roleName + " role by " + UserName;
      }
      else if(record["EntityEventName"] == "DELETE") {
        title = "Removed from " + organizationName + " organization as " + roleName + " role by " + UserName;
      }
    }
  }

  let description = "";
  const rows = [];
  for (const key in record) {
    if(key == "RoleName") {
      if(record["RoleName"] != null && record.hasOwnProperty("EntityEventName")) {
        let roleName = record["RoleName"];
        if(rolesMapping != null && rolesMapping[record["RoleName"]] != null && rolesMapping[record["RoleName"]] != undefined) {
          roleName = rolesMapping[record["RoleName"]];
        }
        if (record["EntityEventName"] == "INSERT") {
          rows.push(<Text fz="sm">{"Role"}
            <List spacing="xs" size={"xs"}>
              <List.Item key={`2_${new Date().getTime()}_${idx}}`}
                icon={
                  <ThemeIcon color="green" size={"xs"} radius="xl">
                    <FontAwesomeIcon icon={faCheck} />
                  </ThemeIcon>
                }
              ><Text fz="xs">{roleName}</Text></List.Item>
            </List>
          </Text>);

          if(EntityName == "User"
            && record.hasOwnProperty("DuplicateUserRoleUpdate") 
            && record["DuplicateUserRoleUpdate"] != null && record["DuplicateUserRoleUpdate"] == "false")
          {
            let userRoleChanges = record["Changes"];
            if(userRoleChanges != null) {
              for(const changeKey in userRoleChanges) {
                if(changeKey == "StaffId" || changeKey == "HashStaffId" || changeKey == "SkipTwoFactorAuth")
                {
                  rows.push(<Text fz="sm">{changeKey}
                    <List spacing="xs" size={"xs"} >
                      <List.Item key={`1_${new Date().getTime()}_${idx}`} icon={
                        <ThemeIcon color="green" size={"xs"} radius="xl">
                          <FontAwesomeIcon icon={faCheck} />
                        </ThemeIcon>
                      }><Text fz="xs">{userRoleChanges[changeKey].Item2 ? userRoleChanges[changeKey].Item2.toString() : ""}</Text></List.Item>
                    </List>
                  </Text>);
                }
              }
            }
          }
        } else if (record["EntityEventName"] == "DELETE") {
          rows.push(<Text fz="sm">{"Role"}
            <List spacing="xs" size={"xs"}>
              <List.Item key={`0_${new Date().getTime()}_${idx}`} icon={
                <ThemeIcon color="red" size={"xs"} radius="xl">
                  <FontAwesomeIcon icon={faXmark} />
                </ThemeIcon>
              }><Text fz="xs">{roleName}</Text></List.Item>
            </List>
          </Text>);
        } else if(record["EntityEventName"] == "UPDATE"){
          if(EntityName == "User"
            && record.hasOwnProperty("DuplicateUserRoleUpdate") 
            && record["DuplicateUserRoleUpdate"] != null && record["DuplicateUserRoleUpdate"] == "false")
          {
            let userRoleChanges = record["Changes"];
            if(userRoleChanges != null) {
              for(const changeKey in userRoleChanges) {
                if(changeKey == "StaffId" || changeKey == "HashStaffId" || changeKey == "SkipTwoFactorAuth")
                {
                  rows.push(<Text fz="sm">{changeKey}
                    <List spacing="xs" size={"xs"} >
                      <List.Item key={`0_${new Date().getTime()}_${idx}`} icon={
                        <ThemeIcon color="red" size={"xs"} radius="xl">
                          <FontAwesomeIcon icon={faXmark} />
                        </ThemeIcon>
                      }><Text fz="xs">{userRoleChanges[changeKey].Item1 ? userRoleChanges[changeKey].Item1.toString() : ""}</Text></List.Item>
                      <List.Item key={`1_${new Date().getTime()}_${idx}`} icon={
                        <ThemeIcon color="green" size={"xs"} radius="xl">
                          <FontAwesomeIcon icon={faCheck} />
                        </ThemeIcon>
                      }><Text fz="xs">{userRoleChanges[changeKey].Item2 ? userRoleChanges[changeKey].Item2.toString() : ""}</Text></List.Item>
                    </List>
                  </Text>);
                }
              }
            }
          }
        }
      }
    }
    else if (record.hasOwnProperty(key) && key != "UpdatedDate" && key != "UpdatedBy"
      && key != "CreatedDate" && key != "CreatedBy" && key != "DeleteBy"
      && !key.includes("Id")){ 
      if (record[key] != null && record[key].hasOwnProperty("Item1") && record[key].Item1!=null) {
        if (EventName == "UPDATE") {
          rows.push(<Text fz="sm">{key}
            <List spacing="xs" size={"xs"} >
              <List.Item key={`0_${new Date().getTime()}_${idx}`} icon={
                <ThemeIcon color="red" size={"xs"} radius="xl">
                  <FontAwesomeIcon icon={faXmark} />
                </ThemeIcon>
              }><Text fz="xs">{record[key].Item1 ? record[key].Item1.toString() : ""}</Text></List.Item>
              <List.Item key={`1_${new Date().getTime()}_${idx}`} icon={
                <ThemeIcon color="green" size={"xs"} radius="xl">
                  <FontAwesomeIcon icon={faCheck} />
                </ThemeIcon>
              }><Text fz="xs">{record[key].Item2 ? record[key].Item2.toString() : ""}</Text></List.Item>
            </List>
          </Text>);
        } else {
          rows.push(<Text fz="sm">{key}
            <List spacing="xs" size={"xs"}>
              <List.Item key={`2_${new Date().getTime()}_${idx}}`}
                icon={
                  <ThemeIcon color="green" size={"xs"} radius="xl">
                    <FontAwesomeIcon icon={faCheck} />
                  </ThemeIcon>
                }
              ><Text fz="xs">{record[key].Item2 ? record[key].Item2.toString() : ""}</Text></List.Item>
            </List>
          </Text>);
        }
      }      
    }
  }

  if (rows.length == 0) {
    return (<></>);
  }

  return (
    <Timeline.Item key={idx} title={<>{title}</>}>
      <Text color="dimmed" size="sm">{description}</Text>
      {rows}
      <Text size="sm" mt={4}>
        <Group>
          <Text>{dayjs(Timestamp).format("L LT")}</Text>
          <Text>{dayjs(Timestamp).fromNow()}</Text>
        </Group>
      </Text>
    </Timeline.Item>
  );
}

function DisplayHeaderLine(headerData) {
  let headText = "Summary:";
  var CreationDate = headerData?.CreationDate;
  var CreatedBy = headerData?.CreatedBy;
  var LastModifiedDate = headerData?.LastModifiedDate;
  var LastModifiedBy = headerData?.LastModifiedBy;
  if (CreatedBy && LastModifiedBy) {
    return (
      <Timeline.Item title={headText} key={new Date().getTime()}>
        <Text color="dimmed" size="sm">Created by {CreatedBy}</Text>
        <Text size="xs" mt={4}>
          <Group>
            <Text>{dayjs(CreationDate).format("L LT")}</Text>
            <Text>{dayjs(CreationDate).fromNow()}</Text>
          </Group>
        </Text>
        <Text color="dimmed" size="sm">LastModified by {LastModifiedBy}</Text>
        <Text size="xs" mt={4}>
          <Group>
            <Text>{dayjs(LastModifiedDate).format("L LT")}</Text>
            <Text>{dayjs(LastModifiedDate).fromNow()}</Text>
          </Group>
        </Text>
      </Timeline.Item>
    );
  } else if (CreatedBy) {
    return (
      <Timeline.Item title={headText}>
        <Text color="dimmed" size="sm">Created by {CreatedBy}</Text>
        <Text size="xs" mt={4}>
          <Group>
            <Text>{dayjs(CreationDate).format("L LT")}</Text>
            <Text>{dayjs(CreationDate).fromNow()}</Text>
          </Group>
        </Text>
      </Timeline.Item>
    );
  } else if (LastModifiedBy) {
    return (
      <Timeline.Item title={headText}>
        <Text color="dimmed" size="sm">LastModified by {LastModifiedBy}</Text>
        <Text size="xs" mt={4}>
          <Group>
            <Text>{dayjs(LastModifiedDate).format("L LT")}</Text>
            <Text>{dayjs(LastModifiedDate).fromNow()}</Text>
          </Group>
        </Text>
      </Timeline.Item>
    );
  }
}

export function EntityUpdateHistory({ context, entityId, LastModifiedDate, exclusions, userOrganizationId }) {
  let organizationId = useAppSelector(selectOrganizationId);
  const [page, setPage] = useState(1);
  const [showData, setShowData] = useState([]);
  const [haveMoreData, setHaveMoreData] = useState(true);
  const stackRef = useRef();
  const { scrollTargetHit, stopScrollEvents, hasScrollBar } = useScrollHook(stackRef?.current?.parentElement.parentElement, stackRef, 90);
  const [checkScrollState, setCheckScrollState] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [rolesMapping, setRolesMapping] = useState(null);

  let showUserLink = true;
  if(context.toLowerCase() == "users" && userOrganizationId != undefined && userOrganizationId != null) {
    if(userOrganizationId != organizationId) {
      showUserLink = false;
      organizationId = userOrganizationId;
    }
  }
  else if(context.toLowerCase() == "organization") {
    if(entityId != organizationId) {
      showUserLink = false;
      organizationId = entityId;
    }
  }

  const {
    data: organizationInfo,
    error: organizationQueryError,
    isLoading: organizationInfoLoading,
    isSuccess: organizationInfoLoaded } = useGetApiOrganizationGetOrganizationQuery(organizationId ? { 
      "organizationId": organizationId
  } : skipToken, { refetchOnMountOrArgChange: true }); 
  
  useEffect(() => {
    if (organizationInfo != null && organizationName == "") {
      setOrganizationName(organizationInfo.organizationName);
    }
  }, [organizationInfo]);
     
  const { data: rolesInfo,
    error: rolesQueryError,
    isLoading: rolesInfoLoading,
    isSuccess: rolesInfoLoaded } = useGetApiRoleGetAllRolesInOrgQuery(organizationId ? {
    "organizationId": organizationId,
  } : skipToken, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (rolesInfo != null && rolesMapping == null) {
      const roleMap = rolesInfo.reduce((acc, role) => {
        acc[role.id] = role.name;
        return acc;
      }, {});
      setRolesMapping(roleMap);
    }
  }, [rolesInfo]);

  let { data, isLoading, isSuccess, isError } = useGetApiAuditEventByOrganizationIdAndEntityIdPageQuery(organizationId ? {
    "organizationId": organizationId,
    "entityId": entityId,
    "excludeEvents":exclusions??null,
    "page": page
  } : skipToken, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccess === false || isLoading === true) {
      return;
    }
    var newData = [];
    data?.Data?.forEach(element => {
      newData.push(element);
    });
    setShowData([...showData, ...newData]);
    if (data?.Data?.length === 0) {      
      setHaveMoreData(false);
      stopScrollEvents();
    } else {
      setCheckScrollState(true);
    }    
  }, [data]);

  useEffect(() => {
    if (checkScrollState == true) {
      if(!hasScrollBar()){
        setTimeout(() => {
          setPage(page + 1);
        }, 250);
      }
      setCheckScrollState(false);
    }
  },[checkScrollState])

  useEffect(() => {
    if (isSuccess === false || isLoading === true) {
      return;
    }
    if (scrollTargetHit && haveMoreData) {      
      setTimeout(() => {
        setPage(page + 1);
      }, 250);
    }
  }, [scrollTargetHit]);

  let { data: headerData } = useGetApiAuditHeadByOrganizationIdAndEntityIdQuery(organizationId ? {
    "organizationId": organizationId,
    "entityId": entityId
  } : skipToken);

  // @ts-ignore
  const timelineItems = function () {
    let dataToShow = showData?.map((item, idx) => DisplayLineItem(item, idx, organizationName, rolesMapping, showUserLink));   
    return dataToShow;
  }

  const headerline = function () {
    let elements = DisplayHeaderLine(headerData);
    return elements;
  }

  return (
    <Stack ref={stackRef}>
      <Text>View {context} update history</Text>

      <Timeline active={-1} bulletSize={20} lineWidth={2}>
        {
          headerline()
        }
        {
          timelineItems()
        }
      </Timeline>            
      {
        haveMoreData ?
          <Flex direction="column" >
            <Flex align="center">
              <Skeleton height={24} circle mr="sm" />
              <Skeleton height={8} radius="xl" width="25%" />
            </Flex>
            <Flex direction="column">
              <Skeleton height={8} ml="36px" mt="xs" radius="xl" width="50%" />
              <Skeleton height={8} ml="36px" mt="xs" radius="xl" width="60%" />
              <Skeleton height={8} ml="36px" mt="xs" radius="xl" width="40%" />
            </Flex>
          </Flex> : showData && showData.length > 0 ? <></> : <Text>No update history found.</Text>
      }
    </Stack>
  );
}