import { useTranslation } from "react-i18next";
import React from "react";
import { PasswordInput } from "@mantine/core";
import { useUserPasswordFormContext } from "./UserPasswordFormContext";

export function UserPasswordInputs() {
    const form = useUserPasswordFormContext()
    const {t} = useTranslation()

    return (
        <>
            <PasswordInput label={t("userPassword.currentPassword")}
                           {...form.getInputProps('currentPassword')} />

            <PasswordInput label={t("userPassword.newPassword")}
                           {...form.getInputProps('newPassword')}
                            />

            <PasswordInput label={t("userPassword.confirmNewPassword")}
                           {...form.getInputProps('confirmNewPassword')}
                           />
        </>
    )
}